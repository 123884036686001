export const environment = {
    production: true,
    single: 'https://single-variant.beta1.gva.semgenome.com/',
    server: 'UI_SERVER_BETA_AZURE',
    AWSurl: 'https://usermanagement.beta1.gva.semgenome.com',
    report_url: 'https://reports.beta1.gva.semgenome.com',
    usersFileContainerName: 'user-file-system',
    azureStorageUrl: 'https://betagvauserfilesfruz.blob.core.windows.net',
    user : 'https://usermanagement.beta1.gva.semgenome.com/',
    status_case_init: 'https://case-init.beta1.gva.semgenome.com/',  // Case INIT 
    file: 'https://upload.beta1.gva.semgenome.com/file/',
    hpo : 'https://search.beta1.gva.semgenome.com/',
    rules : 'https://rules.beta1.gva.semgenome.com/',
    info: 'https://input-info.beta1.gva.semgenome.com/',
    advanced_settings : 'https://advanced-settings.beta1.gva.semgenome.com/',  
    status_usergva: 'https://usergva.beta1.gva.semgenome.com/',
    filters: 'https://filters.beta1.gva.semgenome.com/',
    filter_panel: 'https://filter-panel.beta1.gva.semgenome.com/',
    cnv_acmg  : 'https://cnv-acmg.beta1.gva.semgenome.com/',
    cnv: 'https://cnv.beta1.gva.semgenome.com/',
    acmg : 'https://snv-acmg.beta1.gva.semgenome.com/',
    status_confirm : 'https://confirm.beta1.gva.semgenome.com/',
    viewset : 'https://kg-search.beta1.gva.semgenome.com/',  // KG Search URL
    pubmed : 'https://pubmed-integration.beta1.gva.semgenome.com/',
    vcf_settings : 'https://vcf-settings.beta1.gva.semgenome.com/',
    report : 'https://reports.beta1.gva.semgenome.com/',
    modify_usergva : 'https://case-init.beta1.gva.semgenome.com/',
    upload_file: 'https://notification.beta1.gva.semgenome.com/',  // Notification
    report_image: 'https://reports.beta1.gva.semgenome.com/fetch', // Haeder and Footer
    key: 'IW84Zmx2a21wKW1hdWw2MzlrMSMxcGZk',
    env: 'azure',
    console: 'https://consoleapi.beta1.gva.semgenome.com/',
    oneDrive: '52fc44c6-6935-42b0-9d64-bcae6632cf82',
    dropBox: 'j2ukatlqr6omal6'
  };
  